import React, { Component } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import "../styles/global.css";

export const query = graphql`
  {
    allAirtable(
      filter: { table: { eq: "US" } }
      sort: { fields: data___Sort, order: ASC }
    ) {
      nodes {
        recordId
        data {
          ID
          Code
          Link
          Link2
          Added
          Taken
          Sort
        }
      }
    }
  }
`;

export default ({ data }) => (
  <div className="container">
    <Helmet>
      <meta charSet="utf-8" />
      <title>N26 US Invites 🏦</title>
      <link rel="canonical" href="https://n26invites.nomadgate.com/" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
    </Helmet>
    <h1>N26 US Invites 🏦</h1>
    <h2>Rules (please read carefully):</h2>
    <ol>
      <li>
        New invites are added daily! If there are no invites left, try again
        tomorrow...
      </li>
      <li>
        When using an invite, please report it as taken! A form should open up
        automatically when you click a "Claim invite" link, if it doesn't, click
        the "Report taken" link next to it.{" "}
        <strong>
          Only report it as taken once you've completed the "Create Account"
          step!
        </strong>
      </li>
      <li>
        When clicking "Claim invite" the invitation should open in a new
        tab/window. If it doesn't, try right-clicking the link and selecting
        "Open in new tab" or similar in your browser.
      </li>
      <li>
        Only one invite per person! That should go without saying, but please
        only claim one invite. If you have any friends or family who wants to
        join N26 as well, please refer them{" "}
        <a href="https://n26us.paperform.co/">here</a> instead of sharing this
        URL directly. I should have an invite for them within 1-2 days.
      </li>
      <li>
        If you receive a message saying that "N26 is coming to the United
        States" and that you can join the waiting list (after entering your
        email address), that means that either you are not signing up from the
        US (see my point about using a VPN below), or someone already used that
        invite. If the latter, please report it as taken!
      </li>
      <li>
        You need to have a physical US address (no postbox or virtual mailboxes,
        even if street addresses).
      </li>
      <li>
        You also need to have a state ID/driver's license with the same address,
        or a utility bill in your name for the address (no bank statments,
        cable/internet bills etc). If you don't you can't yet open an N26
        account, so please don't waste an invite that someone else could have
        used!
      </li>
      <li>
        Please make sure you sign up from the US (or use a VPN if you are
        travelling—I recommend{" "}
        <a href="https://nomadgate.com/go/nordvpn">NordVPN</a> which also offer
        a 30-day money back guarantee)
      </li>
      <li>
        If you received an N26 invite that you won't use yourself, please
        forward it to n26invites-us@nomad.email and I will add it to the list
        below. Thank you!
      </li>
    </ol>
    <div className="coupon-list">
      <h2>Current invites:</h2>
      {data.allAirtable.nodes.map(node => (
        <div key={node.recordId} className="coupon-card">
          {!node.data.Taken && (
            <h3 className="coupon">Invite #{node.data.ID}</h3>
          )}
          {node.data.Taken && (
            <h3 className="coupon">Invite #{node.data.ID} (reported taken)</h3>
          )}
          <span>
            <em>
              {" "}
              {!node.data.Taken && (
                <a
                  href={node.data.Link}
                  target="_blank"
                  onClick={() =>
                    window.location.replace(
                      "https://n26invitetaken.paperform.co/?code=" +
                        node.data.Code +
                        "&id=" +
                        node.recordId
                    )
                  }
                >
                  Claim invite #{node.data.ID}
                </a>
              )}
              {node.data.Taken && (
                <a href={node.data.Link} target="_blank">
                  Try claiming #{node.data.ID} anyway
                </a>
              )}
            </em>
          </span>
          <span className="expiration">(added: {node.data.Added})</span>
          {node.data.Taken && (
            <span className="expiration">(taken: {node.data.Taken})</span>
          )}
          {!node.data.Taken && (
            <span className="expiration">
              <a
                href={
                  "https://n26invitetaken.paperform.co/?code=" +
                  node.data.Code +
                  "&id=" +
                  node.recordId
                }
                target="_blank"
              >
                Report taken
              </a>
              )
            </span>
          )}
        </div>
      ))}
    </div>
  </div>
);
